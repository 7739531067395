.root {
  background-color: var(--secondary);
  color: var(--main);
  padding: 13px 16px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 300;
  & span {
    font-size: 22px;
    margin-right: 12px;
  }
}
.error {
  background-color: var(--lightRed);
  color: var(--red);
}