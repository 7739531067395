.root {
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--white);
  background-color: var(--main);
  border: none;
  box-shadow: none;
  border-radius: 8px;
  padding: 15px 0;
  width: 100%;
  cursor: pointer;
  transition: all .2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #455fb3;
  }
  &:disabled {
    cursor: default;
    opacity: .8;
    background-color: var(--main);
  }
}
.light {
  color: var(--main);
  background-color: var(--secondary);
  &:hover {
    color: var(--white);
    background-color: var(--main);
  }
  &:disabled {
    cursor: default;
    opacity: .8;
    background-color: var(--secondary);
  }
}