.root {
    background-color: #f5f5fa;
    padding: 24px 30px;
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .title {
      >h1 {
        font-size: 20px;
        color: var(--mainText);
      }
    }
  }
  .wrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;
    margin-top: 24px;
  }
  .form {
    width: 75%;
    // .row {
    //   width: 100%;
    //   margin: 0 -12px;
    //   display: flex;
    //   flex-wrap: wrap;
    // }
    .field {
      width: 50%;
      padding: 0 12px;
      margin-bottom: 24px;
    }
    .action {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 50px;
      .create, .cancel {
        width: auto;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 0.875rem;
      }
      .cancel {
        margin-left: 8px;
      }
    }
  }