.inputWrapper {
	padding: 27px 0 10px;
	display: flex;
	align-items: center;
	position: relative;

	& .line {
		border-bottom: 1px solid #f0f0f3;
		bottom: 0;
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	& .activeLine {
		border-bottom: 1px solid #f0f0f3;
		bottom: 0;
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		transform: scale(0);
		transition: all 350ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	}
	& .label {
		display: block;
		font-size: 0.875rem;
		color: var(--secondaryText);
		font-weight: 700;
		position: absolute;
		top: 0;
		left: 0;
	}
	& .icon {
		display: flex;
		align-items: center;
		span {
			font-size: 16px;
			color: var(--secondaryText);
		}
	}
}
.nonePadding {
  padding: 0;
}
.input {
	display: block;
	position: relative;
	color: var(--mainText);
	font-size: 0.875rem;
	line-height: 1.75rem;
	border: none;
	width: 100%;
	background-color: initial;
	&::placeholder {
		color: var(--secondaryText);
	}
	&:focus {
		~ .activeLine {
			border-bottom: 1px solid var(--main);
      transform: scaleX(1);
		}
		~ .label {
			color: var(--main);
		}
		~ .icon {
			& span {
				color: var(--main);
			}
		}
	}
}
.inputWrapper.invalidInputWrapper {
	& .label {
		color: var(--red);
	}
	& .line, & .activeLine {
		border-bottom: 1px solid var(--red);
	}
	& .icon {
		& span {
			color: var(--red);
		}
	}
}
.error {
	color: var(--red);
	font-size: 0.75rem;
}