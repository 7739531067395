@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp);

:root {
  --main: #5e81f4;
  --secondary: #eff3fe;
  --mainText: #1c1d21;
  --secondaryText: #8181a5;
  --white: #ffffff;
  --red: #ff808b;
  --lightRed: #fff3f4;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

body {
  color: var(--mainText);
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, li {
  list-style: none;
}
body #main-wrapper {
  padding-left: 286px;
  transition: all .3s;
}
.loading {
  padding: 20px 18px;
}