.root {
  padding: 24px 30px;
  .image {
    display: inline-block;
    animation: spin 0.5s linear infinite;
    width: 24px;
    height: 24px;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.wrapContent {
  position: relative;
  .image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    animation: spin 0.5s linear infinite;
    width: 24px;
    height: 24px;
  }
  & .children  {
    opacity: .5;
  }
  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
.inActive {
  .image {
    display: none;
  }
  & .children {
    opacity: 1;
  }
}