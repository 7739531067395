.root {
  transition: all .3s;
  margin-bottom: 16px;
  border-radius: 4px;
  position: relative;

  &:hover {
    & .tooltip {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
  }

  & a, & > span {
    padding: 13px;
    display: flex;
    align-items: center;
    color: var(--secondaryText);
    position: relative;
    cursor: pointer;
    background-color: transparent;

    & .icon {
      font-size: 1.375rem;
      margin-right: 10px;
      transition: all .3s;
    }
    & .name {
      transition: all .3s;
      white-space: nowrap;
    }
    & .line {
      width: 4px;
      height: 100%;
      background-color: var(--main);
      position: absolute;
      left: -9.5px;
      // transition: all .3s;
      opacity: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    & .expand {
      margin-left: auto;
      transition: all .3s;
    }
    & .activeExpand {
      transform: rotate(90deg);
    }
    &:hover {
      background-color: var(--secondary);
      border-radius: 10px;
      & .name, & .icon, & .expand {
        color: var(--main);
      }
    }
  }
  .tooltip {
    position: absolute;
    left: calc(100% + 9.5px);
    top: 50%;
    transform: translateY(-50%) scale(0);
    width: max-content;
    background-color: var(--main);
    color: var(--white);
    padding: 5px 15px;
    border-radius: 5px;
    opacity: 0;
    transition: all .2s ease-in-out;
    z-index: 99999;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0,0, 0.2), 0 9px 28px 8px rgba(0, 0,0, 0.05);
    &::after {
      content: "";
      position:absolute;
      right: 100%;
      margin-left:-5px;
      top:50%;
      transform:translateY(-50%);
      border: 4px solid var(--main);
      border-color: transparent var(--main) transparent transparent;
    }
  }
}
.activeItem {
  & > a {
    background-color: var(--secondary);
    border-radius: 10px;
    & .name, & .icon, & .expand {
      color: var(--main);
    }
    & .line {
      opacity: 1;
    }
   }
}
.dropdown {
  overflow: hidden;
  height: max-content;
  transition: all 0.2s linear;
}
.menuActive {
  & a, & > span {
    & .line {
      left: -18px;
    }
  }
}