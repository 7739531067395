.root {
  width: 286px;
  background: var(--white);
  border-right: 1px solid #f0f0f3;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all .3s;
  // overflow: hidden;
  overflow-y: scroll;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 18px;
  border-bottom: 2px solid var(--main);
  min-height: 78px;

  & .logo {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  & .pageName {
    color: var(--secondaryText);
    letter-spacing: 2px;
    font-size: 1.5rem;
  }

  & .arrow {
    margin-left: auto;
    background-image: url("../../assets/images/double-arrow.svg");
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background-image: url("../../assets/images/active-double-arrow.svg");
    }
  }
}

.body {
  padding: 20px 18px;

  & .subList {
    padding-left: 18px;
    margin-top: 16px;
  }
}

.inactiveSidebar {
  width: 70px;

  & .body {
    padding: 20px 9.5px;
  }

  & .header {
    justify-content: center;

    & .logo {
      display: none;
    }

    & .pageName {
      display: none;
    }

    & .arrow {
      margin-left: 0;
      transform: rotate(180deg);
      background-image: url("../../assets/images/active-double-arrow.svg");
    }
  }

  & .body {
    & .list {
      & .item {
        span:nth-child(2) {
          opacity: 0;
        }
      }
    }

    & .subList {
      display: none;
    }

  }
}