@import "../../assets/styles/mixin";

.root {
	background-image: url("../../assets/images/bg-login-mobile.svg");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include md {
		background-image: none;
		flex-direction: row;
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	color: var(--white);
	@include md {
		display: none;
	}
	& .logo {
		width: 49px;
		height: 49px;
		margin-bottom: 14px;
	}
	& .pageTitle {
		font-weight: 400;
		font-size: 2rem;
		line-height: 1.3;
	}
}
.desktopHeader {
	display: none;
	margin-bottom: 50px;
	@include md {
		display: block;
	}
	@include xl {
		margin-bottom: 70px;
	}
	& .pageTitle {
		color: var(--mainText);
		margin-bottom: 11px;
		white-space: pre-line;
	}
	& .pageDescription {
		color: var(--secondaryText);
		font-size: 0.875rem;
	}
}
.formWrapper {
	background-color: var(--white);
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include md {
		position: absolute;
		height: 100%;
		flex-grow: 1;
		width: 52%;
		border-top-left-radius: 0;
		border-bottom-right-radius: 15px;
	}
	& .form {
		padding: 25px;
		width: 100%;
		@include md {
			max-width: 420px;
		}
		@include lg {
			padding: 0;
		}
		& .formControl {
			margin-bottom: 17px;
		}
		& .functionBlock {
			margin: 22px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 0.875rem;
			@include xl {
				margin: 35px 0; 
			}
			& .rememberMe {
				& .checkBoxContainer {
					display: inline-flex;
					position: relative;
					cursor: pointer;
					user-select: none;
					align-items: center;
					font-size: inherit;
					& input {
						// position: absolute;
						opacity: 0;
						cursor: pointer;
						height: 0;
						width: 0;
						order: 3
					}
				
					& .labelText {
						order: 2;
						padding-left: 12px;
						font-size: inherit;
						font-weight: 700;
					}
					& .checkmark {
						position: relative;
						order: 1;
						top: 0;
						left: 0;
						height: 18px;
						width: 18px;
						background-color: var(--white);
						border: 1px solid var(--main);
						border-radius: 4px;
						&:after {
							content: "";
							position: absolute;
							display: none;
						}
					}
					&:hover input ~ .checkmark {
						background-color: var(--secondary);
					}
					& input:checked ~ .checkmark {
						background-color: var(--main);
					}
					& input:checked ~ .checkmark:after {
						display: block;
					}
					& .checkmark:after {
						left: 50%;
						top: 50%;
						transform: translate(-65%, -60%) rotate(45deg);
						bottom: 0;
						right: 0;
						margin: auto;
						width: 3px;
						height: 6px;
						border: solid white;
						border-width: 0 3px 3px 0;
						@media(--xl) {
							width: 5px;
							height: 10px;
						}
					}
				}
			}
			& .recoverPassword {
				font-weight: 700;
				color: var(--main);
				&:hover {
					text-decoration: underline;
				}
			}
		}
		& .alert {
			margin-bottom: 16px;
		}
	}
}
.desktopBackground {
	@include md {
		margin-left: auto;
		width: 50%;
		height: 100vh;
		background-image: url("../../assets/images/bg-login-desktop.svg");
		background-size: cover;
		background-position: center;
	}
}