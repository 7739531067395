.inputWrapper {
	padding: 27px 0 10px;
	display: flex;
	align-items: center;
	position: relative;

	& .line {
		border-bottom: 1px solid #f0f0f3;
		bottom: 0;
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	& .activeLine {
		border-bottom: 1px solid #f0f0f3;
		bottom: 0;
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		transform: scale(0);
		transition: all 350ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	}
	& .label {
		display: block;
		font-size: 0.875rem;
		color: var(--secondaryText);
		font-weight: 700;
		position: absolute;
		top: 0;
		left: 0;
	}
	& .icon {
		display: flex;
		align-items: center;
		span {
			font-size: 16px;
			color: var(--secondaryText);
		}
	}
}
.inputWrapper.invalidInputWrapper {
	& .label {
		color: var(--red);
	}
	& .line, & .activeLine {
		border-bottom: 1px solid var(--red);
	}
	& .icon {
		& span {
			color: var(--red);
		}
	}
}
.error {
	color: var(--red);
	font-size: 0.75rem;
}
/**
 * Main wrapper
 */
 .container {
  width: 100%;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.container *,
.container *::after,
.container *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.value {
  position: relative;
  // z-index: 1;
}
.inputWrapper {
  .container:not(.is-loading):not(.container--multiple) .value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    border-right-color: var(--secondaryText);
    border-bottom-color: var(--secondaryText);
  }
}

/**
* Input
*/
.input {
  display: block;
  height: 28px;
  width: 100%;
  padding: 0 30px 0 0;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  &::placeholder {
		color: var(--secondaryText);
	}
}
.has-focus {
  .select {
    animation: fadeIn 0.2s ease-in-out;
    z-index: 2;
  }
  ~ .activeLine {
    border-bottom: 1px solid var(--main);
    transform: scaleX(1);
  }
  ~ .label {
    color: var(--main);
  }
  ~ .icon {
    & span {
      color: var(--main);
    }
  }
  .value::after {
    border-right-color: var(--main) !important;
    border-bottom-color: var(--main) !important;
  }
}

.input::-webkit-search-decoration,
.input::-webkit-search-cancel-button,
.input::-webkit-search-results-button,
.input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select {
  background: #fff;
  box-shadow: 0 6px 20px 0 rgba(153, 155, 168, 0.3);
  // box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.options {
  list-style: none;
}

/**
* Option row
*/
// .row:not(:first-child) {
//   border-top: 1px solid #eee;
// }

/**
* Option
*/
.option,
.not-found {
  display: block;
  height: 46px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.container--multiple .option {
  height: 48px;
  border: none;
}

.option.is-selected {
  background: var(--main);
  color: #fff;
}

.option.is-highlighted,
.option:not(.is-selected):hover {
  background: var(--secondary);
  color: var(--main);
}

.option.is-highlighted.is-selected,
.option.is-selected:hover {
  background: var(--main);
  color: #fff;
}

/**
* Group
*/
.group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.container.is-disabled {
  opacity: 0.5;
}

.container.is-loading .value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.container:not(.is-disabled) .input {
  cursor: pointer;
}

/**
* Modifiers
*/
.container--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.container:not(.is-loading):not(.container--multiple) .value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.container--multiple .input {
  cursor: initial;
}

.container--multiple .input {
  border-radius: 3px 3px 0 0;
}

.container--multiple:not(.container--search) .input {
  cursor: default;
}

// .container:not(.container--multiple) .input:hover {
//   border-color: #2FCC8B;
// }


.container:not(.container--multiple) .select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.container--multiple .select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(2rem);
  }
}