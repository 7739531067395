.root {
  transition: all .3s;
  margin-bottom: 16px;
  border-radius: 4px;
  > button {
    padding: 13px;
    display: flex;
    align-items: center;
    color: var(--secondaryText);
    position: relative;
    cursor: pointer;
    width: 100%;
    background: none;
    border: none;
    & .icon {
      font-size: 1.375rem;
      margin-right: 10px;
      transition: all .3s;
    }
    & .name {
      font-size: 1rem;
      transition: all .3s;
      white-space: nowrap;
    }
    &:hover {
      background-color: var(--secondary);
      & .name, & .icon, & .expand {
        color: var(--main);
      }
    }
  }
}